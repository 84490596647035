<template>
  <div>
    <v-row v-if="permissions" class='ma-2'>
      <v-col cols=12 lg=6 v-for="category in permissionCategories" :key="category.id">
        <v-card>
          <v-card-title>{{category.description}}</v-card-title>
          <v-card-text>  <v-row dense v-for='permission in groupedPermissions[category.id]' :key='permission.name'>
          <v-col>
            <div class='d-flex grey lighten-5 pl-2 pr-2'>
              <span :class="[permission.isParent ? 'mt-auto mb-auto font-weight-black' : 'mt-auto mb-auto']">{{permission.displayName}}</span>
              <v-switch :disabled='loading' :loading="loading" class='ml-auto mt=auto mb-auto' v-model='permission.active' @change="permissionChange"></v-switch>
            </div>
          </v-col>
          </v-row>
        </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Services from '@/services'
import { groupBy } from '@/utils/helpers'
export default {
  name: 'permissions',
  props: {
    user: Object
  },
  data () {
    return {
      permissions: null,
      loading: false
    }
  },
  computed: {
    permissionCategories () {
      return this.$store.getters.enums.permissionCategories
    },
    groupedPermissions () {
      if (!this.permissions) {
        return []
      }
      return groupBy(this.permissions, 'category')
    }
  },
  methods: {
    async getPermissions () {
      const response = await Services.get('UserManagement/GetPermissions?userId=' + this.user.id)
      this.permissions = response.data
    },
    async permissionChange () {
      try {
        this.loading = true
        await Services.put('UserManagement/UpdatePermissions', {
          userId: this.user.id,
          permissions: this.permissions
        })
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.getPermissions()
  }
}
</script>
